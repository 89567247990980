import React from 'react';
import styled from 'styled-components';
import * as AppContext from '../../AppContext';
import * as Widget from '../../components/Widget';
import * as Ant from 'antd';
import TabBar from '../../components/TabBar';
import TabCollection from './TabCollection';
import TabArticle from './TabArticle';

const TabItems = ['COLLECTION', 'DOCUMENT']; //

function ArticleServicePage(props) {
  const [selectedTab, setSelectedTab] = React.useState(TabItems[1]);
  const [collections, setCollections] = React.useState([]);
  const app = React.useContext(AppContext.Context);
  const fetchCollections = React.useCallback(async () => {
    try {
      setCollections(await app.actions.fetchCollections());
    } catch (ex) {
      app.actions.onApiError(ex);
    }
  }, [app.actions]);

  React.useEffect(() => {
    fetchCollections();
  }, [fetchCollections]);

  return (
    <Outer>
      <Wrapper>
        <TabBar
          items={TabItems.slice(1)}
          onItemClick={setSelectedTab}
          isItemActive={(item) => item === selectedTab}
        />
        <div className="tab-content">
          {selectedTab === TabItems[0] && (
            <TabCollection
              collections={collections}
              fetchCollections={fetchCollections}
            />
          )}
          {selectedTab === TabItems[1] && (
            <TabArticle collections={collections} />
          )}
        </div>
      </Wrapper>
    </Outer>
  );
}

const Outer = styled.div`
  padding: 80px 20px 0px 20px;
  background-color: ${AppContext.Theme.lightBgColor};
  height: 100vh;
  position: relative;
`;

const Wrapper = styled.div`
  height: 100%;
  max-width: 1024px;
  margin: 0 auto;

  & > .tab-content {
    height: calc(100% - 40px);
    overflow: auto;
  }
`;

export default ArticleServicePage;
