import React from 'react';
import styled from 'styled-components';
import * as Ant from 'antd';
import * as AppContext from '../../AppContext';
import InfoCard from '../../components/InfoCard';
import ResourceTitleBar from '../../components/ResourceTitleBar';
import SimpleResourceItem from '../../components/SimpleResourceItem';
import BottomUpSheet from '../../components/BottomUpSheet';
import InputField from '../../components/InputField';

function EditCollection(props) {
  const {collection, putRecord} = props;
  const [values, setValues] = React.useState(
    collection || {
      name: '',
      primary_key: '',
      actions: [],
    },
  );

  const getValue = (field) => values[field];
  const onValueChange = (field) => (e) => {
    setValues({...values, [field]: e.target.value});
  };
  const onValueSelect = (field) => (value) => {
    setValues({...values, [field]: value});
  };

  return (
    <div>
      <InputField
        label="NAME"
        value={getValue('name')}
        onChange={onValueChange('name')}
        style={{maxWidth: 300}}
        disabled={!!collection}
      />

      <InputField
        label="PRIMAY KEY"
        value={getValue('primary_key')}
        onChange={onValueChange('primary_key')}
        style={{maxWidth: 300}}
      />

      <InputField label="ACTIONS" style={{maxWidth: 300}}>
        <Ant.Select
          mode="multiple"
          allowClear
          style={{width: '100%'}}
          placeholder="Please select"
          value={getValue('actions')}
          onChange={onValueSelect('actions')}>
          <Ant.Select.Option value="owner_read">OWNER READ</Ant.Select.Option>
          <Ant.Select.Option value="owner_write">OWNER WRITE</Ant.Select.Option>
        </Ant.Select>
      </InputField>

      {collection && (
        <InputField
          style={{maxWidth: 200}}
          label="CREATED"
          value={new Date(collection.created).toISOString().split('.')[0]}
          disabled
        />
      )}

      {collection && (
        <InputField
          style={{maxWidth: 200}}
          label="UPDATED"
          value={new Date(collection.updated).toISOString().split('.')[0]}
          disabled
        />
      )}

      <Ant.Button
        type="primary"
        style={{marginTop: 32}}
        onClick={() => putRecord(values)}>
        CONFIRM
      </Ant.Button>
    </div>
  );
}

function TabCollection(props) {
  const {collections, fetchCollections} = props;
  const bottomSheetRef = React.useRef();
  const app = React.useContext(AppContext.Context);

  function showEditCollectionBottomSheet(collection) {
    bottomSheetRef.current.open(
      collection?.name || 'Create Collection',
      <EditCollection
        key={collection?.name || Math.random()}
        collection={collection}
        putRecord={async (record) => {
          app.actions.setLoading(true);
          try {
            bottomSheetRef.current.close();
            await app.actions.putCollection(record);
            await fetchCollections();
          } catch (ex) {
            const errorMsg = `API Fail: ${JSON.stringify(ex, null, 2)}`;
            console.warn(errorMsg);
            alert(errorMsg);
          }
          app.actions.setLoading(false);
        }}
      />,
    );
  }

  return (
    <Wrapper>
      <InfoCard style={{marginTop: 20}}>
        <ResourceTitleBar
          title="Collections"
          onCreateClick={() => {
            showEditCollectionBottomSheet(null);
          }}
        />

        {collections.map((collection, idx) => (
          <SimpleResourceItem
            key={idx}
            resource={collection}
            idx={idx}
            onClick={() => showEditCollectionBottomSheet(collection)}
          />
        ))}
      </InfoCard>

      <BottomUpSheet
        getInstance={(inst) => {
          bottomSheetRef.current = inst;
        }}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div``;

export default TabCollection;
