import React from 'react';
import styled from 'styled-components';
import * as AppContext from '../../AppContext';
import InfoCard from '../../components/InfoCard';
import ResourceTitleBar from '../../components/ResourceTitleBar';
import SimpleResourceItem from '../../components/SimpleResourceItem';
import BottomUpSheet from '../../components/BottomUpSheet';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import ArticleEditor from 'rev.sdk.js/Components/ArticleEditor';
import {useOutlet} from 'reconnect.js';
import {Pagination, Input} from 'antd';

const configs = {
  COLLECTION_NAME: 'Article_Default',
};
const INIT_PAGING = {
  limit: 20,
  offset: 0,
};

function TabArticle(props) {
  const {collections = []} = props;
  const [filters, setFilters] = React.useState({
    search: '',
    ...INIT_PAGING,
  });
  const [total, setTotal] = React.useState(0);
  const [documents, setDocuments] = React.useState([]);
  const bottomSheetRef = React.useRef();
  const app = React.useContext(AppContext.Context);
  const [user] = useOutlet('user');
  const collection = collections.find(
    (c) => c.name === configs.COLLECTION_NAME,
  );
  const primaryKey = collection?.primary_key || 'id';

  const fetchDocuments = React.useCallback(async () => {
    const {search, limit, offset} = filters;
    app.actions.setLoading(true);
    try {
      let resp = await JStorage.fetchDocuments(
        collection.name,
        {title: {$regex: search}},
        [],
        {
          offset: limit * offset,
          limit,
        },
      );

      setDocuments(resp.results);
      setTotal(resp.total);
    } catch (ex) {
      app.actions.onApiError(ex);
    }
    app.actions.setLoading(false);
  }, [app.actions, collection, filters]);

  async function confirmBeforeDelete(docId) {
    const confirmResp = prompt('Type YES to confirm delete', 'No');
    if (confirmResp && confirmResp.toLocaleLowerCase() === 'yes') {
      app.actions.setLoading(true);
      try {
        await JStorage.deleteDocument(configs.COLLECTION_NAME, {id: docId});
        await fetchDocuments();
      } catch (ex) {
        app.actions.onApiError(ex);
      }
      app.actions.setLoading(false);
    }
  }

  React.useEffect(() => {
    if (collection) {
      fetchDocuments();
    }
  }, [collection, fetchDocuments]);

  function showBottomSheet(document) {
    bottomSheetRef.current.open(
      (document && `[${collection.name}] ${document[primaryKey]}`) ||
        `Create ${collection.name}`,

      <ArticleEditor
        key={Math.random()}
        collection={collection}
        document={document}
        onClose={() => bottomSheetRef.current.close()}
        onUpdate={fetchDocuments}
        onPreview={(document) => {
          window.open(
            `/article?id=${document.id}&client=${user.client}`,
            '_blank',
          );
        }}
      />,
    );
  }

  const selectStyle = {width: 150};

  function filtersChange(values) {
    setFilters((prev) => ({...prev, ...values}));
  }

  return (
    <Wrapper>
      <Input.Search
        placeholder="搜尋標題"
        allowClear
        onSearch={(search) => filtersChange({search, ...INIT_PAGING})}
        style={{width: 200, marginRight: 10}}
      />

      {(collection && (
        <InfoCard style={{marginTop: 20}}>
          <ResourceTitleBar
            title={collection.name}
            onCreateClick={() => {
              showBottomSheet(null);
            }}
            onRefreshClick={fetchDocuments}
          />
          {documents.map((document) => (
            <SimpleResourceItem
              key={document.id}
              onClick={() => showBottomSheet(document)}
              onDelete={() => confirmBeforeDelete(document.id)}>
              <div>
                {document['title'] || '---'}
                <div style={{color: '#aaa', fontSize: 12, paddingLeft: 10}}>
                  {document['outline']}
                </div>
              </div>
            </SimpleResourceItem>
          ))}
        </InfoCard>
      )) || (
        <InfoCard style={{marginTop: 20}}>
          <ResourceTitleBar title="No Collection Selected" />
        </InfoCard>
      )}

      <Pagination
        total={total}
        current={filters.offset + 1}
        pageSize={filters.limit}
        showSizeChanger={false}
        showQuickJumper
        showTotal={(total) => '共 ' + total + ' 筆'}
        onChange={(page, pageSize) => {
          filtersChange({
            offset: page - 1,
            limit: pageSize,
          });
        }}
        style={{marginTop: 20}}
      />

      <BottomUpSheet
        getInstance={(inst) => {
          bottomSheetRef.current = inst;
        }}
        contentStyle={{maxWidth: 1024}}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding-top: 10px;
  padding-bottom: 80px;

  .scroll-area {
    overflow: initial;
    display: flex;
    flex-direction: column;
  }
`;

export default TabArticle;
